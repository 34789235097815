import React from "react";
import Logo from "../assets/logo.png";

const LazyLoader = () => {
  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100 flex-column">
      <img height="64" alt="IasoNotes" src={Logo} />
      <div className="mt-3">
        <div className="spinner-grow txt-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow txt-secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};
export default LazyLoader;
