import "./App.css";

import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import React, { Suspense, lazy, useEffect } from "react";

import LazyLoader from "./components/LazyLoader";
import ScrollToTop from "./components/ScrollToTop";
import { Toaster } from "react-hot-toast";

const About = lazy(() => import("./pages/About"));
const CommonQuestion = lazy(() => import("./pages/CommonQuestion"));
const Donate = lazy(() => import("./pages/Donate"));
const ForgotPassword = lazy(() => import("./pages/forgotPassword"));
const HowItWorks = lazy(() => import("./pages/HowItworks"));
const Home = lazy(() => import("./pages/home"));
const Login = lazy(() => import("./pages/login"));
const PatientPage = lazy(() => import("./pages/patientpage"));
const Registration = lazy(() => import("./pages/registration"));
const Thankyou = lazy(() => import("./pages/Thankyou"));
const WhyIasoNotes = lazy(() => import("./pages/WhyIasoNotes"));
const Fitness = lazy(() => import("./pages/Fitness"));
const Medical = lazy(() => import("./pages/Medical"));
const Blog = lazy(() => import("./pages/Blog"));
const Press = lazy(() => import("./pages/Press"));
const Legal = lazy(() => import("./pages/Legal"));
const HelpCenter = lazy(() => import("./pages/HelpCenter"));
const Terms = lazy(() => import("./pages/Terms"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Dashboard = lazy(() => import("./pages/Admin/Dashboard"));
const AdvocateDashboard = lazy(() => import("./pages/Advocate/AdvocateDashboard"));
const DoulaDashboard = lazy(() => import("./pages/Doula/DoulaDashboard"));

function App() {
  useEffect(() => {
    document.title = "IasoNotes: Alert family and friends";
  }, []);

  return (
    <Router>
      <Toaster />
      <ScrollToTop>
        <Suspense fallback={<LazyLoader />}>
          <Routes>
            <Route exact path="/" element={<Navigate to="login" />} />
            <Route path="/medical" element={<Medical />} />
            <Route path="/fitness" element={<Fitness />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path="/why-iasonotes" element={<WhyIasoNotes />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/donate" element={<Donate />} />
            <Route path="/thank-you" element={<Thankyou />} />
            <Route path="/common-questions" element={<CommonQuestion />} />
            <Route path="/patient/:patientPageName" element={<PatientPage />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/help" element={<HelpCenter />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/press" element={<Press />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/advocate" element={<AdvocateDashboard/>} />
            <Route path="/doula" element={<DoulaDashboard/>} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

export default App;
